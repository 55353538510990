<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <!-- <div> -->
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline"> Nuevo comercio</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mb-0 pb-0">
        <v-container fluid class="fill-height mb-0 pb-0">
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-img
                    v-on="on"
                    :src="imgSrcBanner"
                    aspect-ratio="1"
                    @click="selectImage('banner')"
                    class="grey lighten-2 cover-up"
                    max-width="100%"
                    max-height="250"
                  >
                    <v-row
                      class="fill-height ma-0"
                      align="end"
                      justify="center"
                    >
                      <v-chip style="width: 20%" class="mb-5 img-tag">
                        Cover
                      </v-chip>
                    </v-row>
                  </v-img>
                </template>
                <span>Cambiar imágen de cover</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-img
                    v-on="on"
                    :src="imgSrcBrand"
                    aspect-ratio="1"
                    class="grey lighten-2 logo-up"
                    max-width="150"
                    @click="selectImage('brand')"
                    max-height="150"
                  >
                    <v-row
                      class="fill-height ma-0"
                      align="end"
                      justify="center"
                    >
                      <v-chip class="mb-1 img-tag">Logo</v-chip>
                    </v-row>
                  </v-img>
                </template>
                <span>Cambiar imágen de logo</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-card
            class="text-left pa-1 ma-1 flex-grow-1"
            :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'white'"
            elevation="0"
            style="border-radius: 17px !important; overflow: hidden"
          >
            <v-card-text class="text--primary">
              <v-container fluid class="fill-height">
                <v-row>
                  <!-- <v-col cols="12" sm="12" md="12"> -->
                  <v-col cols="12" md="6" sm="6">
                    <h2>Nombre del comercio</h2>
                    <input
                      class="control-input"
                      type="text"
                      placeholder="Nombre del comercio"
                      v-model="business.shortName"
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <h2>RTN</h2>
                    <input
                      class="control-input"
                      placeholder="RTN"
                      v-model="business.tinNumber"
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <h2>Persona de contacto</h2>
                    <input
                      class="control-input"
                      type="text"
                      placeholder="Persona a contactar en el negocio"
                      v-model="business.contactName"
                    />
                  </v-col>

                  <v-col cols="12" md="6" sm="6">
                    <h2>Teléfono</h2>
                    <input
                      class="control-input"
                      placeholder="Número de la persona de contacto"
                      v-model="business.phone"
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <h2>Email</h2>
                    <input
                      class="control-input"
                      placeholder="Email"
                      v-model="business.email"
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <h2>Ciudad</h2>
                    <v-select
                      rounded
                      :items="sortedCities"
                      v-model="business.city"
                      item-text="name"
                      item-value=".key"
                      class="mt-5"
                      style="border: 1px solid #8080807a"
                      hide-details
                    />
                  </v-col>

                  <v-col cols="12" md="12">
                    <h2>Dirección</h2>
                    <textarea
                      v-model="business.addressDescription"
                      placeholder="Dirección exacta del negocio"
                      class="control-input"
                      rows="3"
                    ></textarea>
                  </v-col>

                  <v-col cols="12" md="6">
                    <h2>Método de pago</h2>
                    <v-select
                      outlined
                      rounded
                      v-model="business.paymentType"
                      class="mt-5"
                      item-text="displayName"
                      item-value=".key"
                      :items="sortedPayments"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="12" md="6" sm="6">
                  <v-switch
                    :loading="loading"
                    v-model="business.active"
                    label="Activo"
                  ></v-switch>
                </v-col>
                <v-col class="align-end" cols="12" sm="6" md="6">
                  <v-btn
                    @click="save"
                    :loading="loading"
                    width="130px"
                    class="save-btn"
                    color="primary"
                    dark
                    >Guardar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
          <!-- </v-col>
          </v-row> -->
        </v-container>
      </v-card-text>
    </v-card>
    <!-- </div> -->

    <input
      class="upload"
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />
  </div>
</template>

<script>
import { db, fb } from "@/firebase";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import mapPicker from "@/components/map-picker";

export default {
  name: "new-corporative",
  props: [],
  components: {
    mapPicker,
  },
  data() {
    return {
      imgSrcBanner: "",
      imgSrcBrand: "",
      loading: false,
      paymentGateways: [],
      business: {
        active: true,
        deleted: false,
        addressDescription: "",
        paymentType: "",
        city: "",
        country: "8HxIs06o3vDI2woWpqw3",
        createdAt: new Date(),
        createdBy: "",
        email: "",
        shortName: "",
        longName: "",
        phone: "",
        tinNumber: "",
      },

      snackbarText: "",
      snackbar: false,
      location: [],
      imageBrandFile: null,
      imageBannerFile: null,
      imageFile: null,
      cities: [],
    };
  },
  computed: {
    sortedCities: function () {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return this.cities.sort(compare);
    },
    sortedPayments: function () {
      function compare(a, b) {
        if (a.displayName < b.displayName) return -1;
        if (a.displayName > b.displayName) return 1;
        return 0;
      }

      return this.paymentGateways.sort(compare);
    },
  },
  methods: {
    updateLocation(collectionId) {
      return new Promise((resolve, rejected) => {
        let data = {
          collection: "businessesCorporative",
          collectionId,
          latitude: this.location[0],
          longitude: this.location[1],
        };
        var httpGeneralUpdateGeoPoint = fb
          .functions()
          .httpsCallable("httpGeneralUpdateGeoPoint");
        httpGeneralUpdateGeoPoint(data)
          .then((result) => {
            resolve("success");
          })
          .catch((err) => {
            this.loading = false;
            this.snackbar = true;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente";
            console.log(err);
          });
      });
    },
    async save() {
      let validate = await this.validate();
      if (validate) {
        this.loading = true;
        this.business.createdAt = new Date();
        this.business.createdBy = this.$store.state.user[".key"];
        this.business.longName = this.business.shortName;
        this.business.logo = {
          original: "",
          loading: "",
        };

        db.collection("corporateCoupons")
          .add(this.business)
          .then(async (ref) => {
            if (this.imageBannerFile) {
              await this.uploadImage("banner", ref.id);
            }

            if (this.imageBrandFile) {
              await this.uploadImage("brand", ref.id);
            }
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.snackbar = true;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente";
          });
      }
    },

    uploadImage(type, businessId) {
      return new Promise((resolve, reject) => {
        let bucket =
          type == "brand" || type == "banner"
            ? `gs://${process.env.VUE_APP_PROJECT_ID}-freight`
            : null;

        if (bucket) {
          fb.app()
            .storage(bucket)
            .ref()
            .child(`corporateCoupons/${businessId}/${type}`)
            .put(type == "brand" ? this.imageBrandFile : this.imageBannerFile)
            .then(() => {
              resolve("success");
            })
            .catch((err) => {
              this.snackbar = true;
              this.snackbarText =
                "Ocurrió un error inesperado, inténtelo nuevamente";
            });
        }
      });
    },

    validate() {
      return new Promise((resolve, reject) => {
        if (!this.business.shortName) {
          this.snackbarText = "Ingrese el nombre del comercio";
          this.snackbar = true;
          resolve(false);
        } else if (!this.business.city) {
          this.snackbarText = "Seleccione la ciudad";
          this.snackbar = true;
          resolve(false);
        } else if (!this.business.paymentType) {
          this.snackbarText = "Seleccione el método de pago.";
          this.snackbar = true;
          resolve(false);
        }

        resolve(true);
      });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    selectImage(imageType) {
      this.imageType = imageType;
      this.$refs.input.click();
    },

    handleLocation(e) {
      this.location = e;
    },

    showFileChooser() {
      this.$refs.input.click();
    },
    setImage(e) {
      this.imageFile = e.target.files[0];

      if (this.imageType == "banner") {
        this.imageBannerFile = e.target.files[0];
      } else if (this.imageType == "brand") {
        this.imageBrandFile = e.target.files[0];
      }

      if (e.target.files[0].type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (this.imageType == "banner")
            this.imgSrcBanner = event.target.result;
          else this.imgSrcBrand = event.target.result;
          this.modalUploadImage = true;
        };

        reader.readAsDataURL(this.imageFile);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
  },
  async mounted() {
    await this.$binding(
      "cities",
      db.collection("cities").where("deleted", "==", false)
    );

    await this.$binding(
      "paymentGateways",
      db
        .collection("paymentGateways")
        .where("countryId", "==", this.business.country)
        .where("deleted", "==", false)
    );
  },
};
</script>


<style scoped>
.logo,
.cover {
  margin-top: 20px;
  cursor: pointer;
}
.add-tag {
  background-color: rgba(0, 0, 0, 0.37);
}

.add-tag-ico {
  font-size: 25px !important;
  cursor: pointer;
  margin-left: 5px;
}

.btn-modal-add-tags {
  justify-content: center;
}

.upload-container .logo {
  width: 10%;
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -8%;
  margin-left: 20px;
}

.logo-up {
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -100px;
  margin-left: 20px;
  cursor: pointer;
}

.upload-container .banner {
  border: 1px solid black;
  border-radius: 10px;
  max-width: 100%;
  max-height: 250px;
  object-fit: cover;
}

.upload {
  display: none;
}
.cover-up {
  border: 1px solid black;
  border-radius: 10px;
}

.logo-up:hover {
  border: 2px dashed #f06f3d;
  cursor: pointer;
}

.cover-up:hover {
  border: 1px dashed #f06f3d;
  cursor: pointer;
}

.add-image {
  font-size: 30px;
  color: rgba(128, 128, 128, 0.733) !important;
  cursor: pointer;
}
.img-tag {
  background-color: rgba(231, 231, 230, 0.808) !important;
  justify-content: center;
  font-weight: bold;
}

.preview-image {
  max-width: 540px;
  min-width: 540px;
  max-height: 540px;
}

.align-end {
  display: flex;
  justify-content: flex-end;
  align-items: center !important;
}
</style>


